.gameModeBadge {
    border-radius: 0px;
    clip-path: polygon(0% 3%,97% 0%,100% 100%,0% 90%);
    -webkit-clip-path: polygon(0% 3%,97% 0%,100% 100%,0% 90%);
    font-size: 1.6rem;
    margin-bottom: 2rem;
}

.codeBlock {
    clip-path: polygon(0% 3%,97% 0%,100% 100%,0% 90%);
    -webkit-clip-path: polygon(0% 3%,97% 0%,100% 100%,0% 90%);
}

.mapBlockImage .Ripple-parent {
    touch-action: unset;
}

.mapBlockImage .rgba-white-slight {
    background-color: unset;
}

.creatorBlockButtonOuter {
    height: 92px;
}

.creatorBlockButton {
    position: absolute;
    width: 100%;
    bottom: 1rem;
    padding-right: 2.2rem;
}

.mapBlockName {
    font-size: 1.7rem;
    text-transform: uppercase!important;
}
.mapBlockCreator {
    font-size: 1.4rem;
}

.creatorBlockDesc {
    font-size: 1rem;
}
.creatorBlockButtonShow {
    font-size: 1.1rem;
}

@media (min-width: 576px) and (max-width: 767px) {
    .mapBlockName {
    font-size: 1.8rem;
    }
    .mapBlockCreator {
        font-size: 1.5rem;
    }
    .creatorBlockDesc {
        font-size: 1.4rem;
    }
    .creatorBlockButtonShow {
        font-size: 1.5rem;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .mapBlockName {
    font-size: 1.9rem;
    }
    .mapBlockCreator {
        font-size: 1.6rem;
    }
    .creatorBlockDesc {
        font-size: 1.4rem;
    }
    .creatorBlockButtonShow {
        font-size: 1.5rem;
    }
}

@media (min-width: 992px) and (max-width: 1199px) {
    .mapBlockName {
    font-size: 1.9rem;
    }
    .mapBlockCreator {
        font-size: 1.7rem;
    }
    .creatorBlockDesc {
        font-size: 1.4rem;
    }
    .creatorBlockButtonShow {
        font-size: 1.8rem;
    }
}

@media (min-width: 1200px) {
    .mapBlockName {
    font-size: 2.0rem;
    }
    .mapBlockCreator {
        font-size: 1.7rem;
    }
    .creatorBlockDesc {
        font-size: 1.4rem;
    }
    .creatorBlockButtonShow {
        font-size: 1.8rem;
    }
}

@media (min-width: 1800px) {
    .mapBlockName {
    font-size: 2.3rem;
    }
    .mapBlockCreator {
        font-size: 1.8rem;
    }
    .creatorBlockDesc {
        font-size: 1.4rem;
    }
    .creatorBlockButtonShow {
        font-size: 1.8rem;
    }
}