.w-33 {
  width: 33% !important;
}

.card {
  border-radius: 0px!important;
}
p {
  font-size: 1.4rem;
}

.bg-blue {
  background-color: #0dc1fc;
}

#mainContainer > .row {
  margin-right: 0px;
  margin-left: 0px;
}

.poligonButton1 {
  clip-path: polygon(0% 3%,97% 0%,100% 100%,0% 90%);
  -webkit-clip-path: polygon(0% 3%,97% 0%,100% 100%,0% 90%);
}

footer {
  font-size: 1.3rem;
}

.outerButtonAllMaps {
  position: absolute;
    bottom: 0;
    width: 100%;
}

.polyCard1 {
  -webkit-clip-path: polygon(2% 100%,0 0,5% 1%,8% 5%,8% 1%,98% 4%,100% 100%);
  clip-path: polygon(2% 100%,0 0,5% 1%,8% 5%,8% 1%,98% 4%,100% 100%);
  height: 12rem;
  background-color: #f0ed63;
}

.polyCard2 {
  -webkit-clip-path: polygon(0 100%,2% 5%,90% 0,90% 5%,93% 0,100% 0,96% 100%);
  clip-path: polygon(0 100%,2% 5%,90% 0,90% 5%,93% 0,100% 0,96% 100%);
  height: 12rem;
  background-color: #f0ed63;
}

.polyCardText {
    position: relative;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

html,
body,
main,
#root,
#root > div:first-child,
.fullHeight {
  /* height: 100%; */
  /* overflow-x: hidden; */
}

#mainContainer, #mainContainer > div {
  /* overflow-x: hidden; */
}

.homeFront {
  background-color: #04c7ff;
  background-image: url("https://s3.eu-central-1.amazonaws.com/cdn.eu.com/c/islandcodes/img/spikesBlueTop.png"), url("https://cdn.eu.com/c/islandcodes/img/spikesBlueBottom.png");
  background-position: top left, bottom;
 background-repeat: no-repeat, no-repeat;
 background-size: contain, contain;
}

.homeFront:after {
  content: "";
  display: block;
  background: #e25656;
  position: absolute;
  left: 0;
  right: 0;
  bottom: -1em;
  height: 6em;
  -webkit-clip-path: polygon(0 70%, 100% 0, 100% 100%, 0 100%);
  clip-path: polygon(0 70%, 100% 0, 100% 100%, 0 100%);
}

.featured {
  background-image: url("https://cdn.eu.com/c/islandcodes/img/spikesRed.png"), linear-gradient(#e25656, #841919);
  background-repeat: round;
}

.featuredHeading {
  text-shadow: 3px 3px #1b1b1b;
}

.featuredCard1 {
  background-image: url(https://cdn.eu.com/c/islandcodes/img/featuredCard1.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-color: transparent;
  box-shadow: none;
}

.featuredCard2 {
  background-image: url(https://cdn.eu.com/c/islandcodes/img/featuredCard2.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-color: transparent;
  box-shadow: none;
}

.featuredCard3 {
  background-image: url(https://cdn.eu.com/c/islandcodes/img/featuredCard3.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-color: transparent;
  box-shadow: none;
}

.addIsland {
  background-color: #0dc1fc;
  background-image: url("https://s3.eu-central-1.amazonaws.com/cdn.eu.com/c/islandcodes/img/spikesBlueTop.png"), url("https://cdn.eu.com/c/islandcodes/img/spikesBlueBottom.png");
  background-position: top left, bottom;
 background-repeat: no-repeat, no-repeat;
 background-size: contain, contain;
}



.featured:after {
  content: "";
  display: block;
  background: #0dc1fc;
  left: 0;
  right: 0;
  bottom: -1em;
  margin-bottom: -1px;
  height: 6em;
  -webkit-clip-path: polygon(0 70%, 100% 0, 100% 100%, 0 100%);
  clip-path: polygon(0 70%, 100% 0, 100% 100%, 0 100%);
}

.mainImage {
  max-width: 80%;
  margin-top: 8rem;
}

.figure1 {
  width: 33%;
  margin-top: -12rem;
}

.figure2 {
  width: 40%;
  margin-top: -19rem;
}

.allMapsBtn {
  bottom: -10rem;
  width: 17rem;
  animation: bounce2 5s infinite;
  -webkit-animation: bounce 4s infinite;
}

.addIslandImages {
  width: 80%;
}

.addIsland:after {
  content: "";
  display: block;
  background: #ffb864;
  left: 0;
  right: 0;
  bottom: -1em;
  margin-bottom: -1px;
  height: 6em;
  -webkit-clip-path: polygon(0 0, 100% 60%, 100% 100%, 0% 100%);
clip-path: polygon(0 0, 100% 60%, 100% 100%, 0% 100%);
}

.handy {
  width: 30rem;
  margin-top: -7rem;
  max-width: 90%;
}

.ButtonsSection {
  background-image: linear-gradient(#ffb864, #ff6d32);
  background-repeat: no-repeat;
}



.ButtonsSection:after {
  content: "";
  display: block;
  background: #000;
  left: 0;
  right: 0;
  bottom: -1em;
  margin-bottom: -1px;
  height: 6em;
  -webkit-clip-path: polygon(0 70%, 100% 0, 100% 100%, 0 100%);
  clip-path: polygon(0 70%, 100% 0, 100% 100%, 0 100%);
}

.FAQ {
  background-color: #000;
}

.fortnite-btn {
  position: relative;
  font-size: 2em;
  color: #fff;
  margin: 0 auto;
  background: transparent;
  color: #000;
  font-weight: 900;
  text-transform: uppercase;
  width: 250px;
  padding: 18px 0;
  text-align: center;
  line-height: 1;
  cursor: pointer;
  -webkit-clip-path: polygon(0 0,100% 0,98% 100%,2% 100%);
  clip-path: polygon(0 0,100% 0,98% 100%,2% 100%);
}

.fortnite-btn-sm {
  font-size: 1.2em!important;
  width: 100%;
  padding-left: 1.4rem;
  padding-right: 1.4rem;

}

.fortnite-btn-sm:after {
  height: 45px!important;
}

.fortnite-btn:hover:after {
  background-color: #eeea4c;
}

.fortnite-btn span, .fortnite-btn button {
  position: relative;
  z-index: 100;
  background-color: #f0ed63;
}

.fortnite-btn:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 65px;
  transition: background-color .3s ease-in-out;
  box-shadow: 0 6px 12px -4px rgba(0, 0, 0, 0.705);
  transform-origin: 50% 50% 20px;
  background-color: #f0ed63;
}

.polycard-1 {
  
  -webkit-clip-path: polygon(0 0, 100% 0, 99% 100%, 1% 100%);
clip-path: polygon(0 0, 100% 0, 99% 100%, 1% 100%);
}

@-webkit-keyframes bounce {
  0% {
transform: scale(1,1) translate(0px, 0px);
}

30%{
transform: scale(1,0.8) translate(0px, 8px); 
}

75%{
transform: scale(1,1.1) translate(0px, -15px); 
}

100% {
transform: scale(1,1) translate(0px, 0px);
}
}

@keyframes bounce2 {
  0% {
    transform: scale(1,1) translate(0px, 0px);
    }
    
    30%{
    transform: scale(1,0.8) translate(0px, 8px); 
    }
    
    75%{
    transform: scale(1,1.1) translate(0px, -15px); 
    }
    
    100% {
    transform: scale(1,1) translate(0px, 0px);
    }
}

.figureBlock {
  position: absolute;
  right: 2rem;
  bottom: -6rem;
  width: 33%;
}


@media (max-width: 576px) {

  html,
  body,
  main,
  #root,
  #root > div:first-child,
  .fullHeight {
    /* height: 100%; */
  }

  .mainImage {
    max-width: 90%;
    margin-top: 4rem;
  }

  .figure1 {
    width: 40%;
    margin-top: 0rem;
  }

  .figure2 {
    width: 50%;
    margin-top: -3.5rem;
  }
  .figureBlock {
    width: 45%;
    right: 0px;
  }



  .allMapsBtn {
    bottom: 3.5rem;
    width: 6rem;
  }
  .homeFront:after {
    -webkit-clip-path: polygon(0 6%, 100% 0, 100% 100%, 0% 100%);
    clip-path: polygon(0 50%, 100% 0, 100% 100%, 0% 100%);
}
.polyCard1, .polyCard2 {
  height: 5rem;
  width: 75%;
margin-left: auto;
margin-right: auto;
}

}

@media (min-width: 401px) and (max-width: 740px) {


}

@media (min-width: 576px) {

  .featuredHeading {
      font-size: 6rem;
      font-weight: 300;
      line-height: 1.2;
  }
}

@media (min-width: 741px) and (max-width: 850px) {

  .figure1 {
    width: 33%;
    margin-top: -3rem;
  }

  .figure2 {
    width: 40%;
    margin-top: -9rem;
  }
 
  .allMapsBtn {
    bottom: 3rem;
    width: 10rem
  }
}

@media (min-width: 741px) and (max-width: 1199px) {

  .allMapsBtn {
    bottom: 3rem;
    width: 12rem
  }
}



@media (min-width: 1200px) and (max-width: 1599px) {
  .mainImage {
    max-width: 900px;
  }
  .allMapsBtn {
    bottom: 3.5rem;
    width: 14rem
  }
  
}

@media (min-width: 1600px) {

  .mainImage {
    max-width: 900px;
  }
  .allMapsBtn {
    bottom: 3.5rem;
    width: 17rem
  }
  
}