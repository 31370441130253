


.FeaturedBlock {
    background-image: url(https://cdn.eu.com/c/islandcodes/img/spikesRed.png), linear-gradient(#e25656, #841919);;
    background-repeat: round;
}

.carousel-multi-item {
    margin-bottom: 3rem;
}


.allMapsAreaFetured {
    background-color: #dddddd;
}

.fortnite-btn-code:after {
    height: 30px!important
}

.BlockWinnerOuter {
    background-image: url("https://s3.eu-central-1.amazonaws.com/cdn.eu.com/c/islandcodes/img/spikesOrange.png"), linear-gradient(#ff8643, #ff6d32);
    background-repeat: round;
    background-size: cover;
}

.BlockWinnerOuter:after {
    content: "";
    display: block;
    background: #dfa130;
    left: 0;
    right: 0;
    bottom: -1em;
    margin-bottom: -1px;
    height: 6em;
    -webkit-clip-path: polygon(0 0, 100% 60%, 100% 100%, 0% 100%);
  clip-path: polygon(0 0, 100% 60%, 100% 100%, 0% 100%);
  }


  .text-shadow {
    text-shadow: 3px 3px #1b1b1b;
  }

  .carousel-multi-item .controls-top .btn-floating {
    background: #ff8181;
}

.carousel-multi-item .carousel-indicators li {

    background-color: #ff8181;

}

.carousel-multi-item .carousel-indicators .active {
    background-color: #ffb4b4;

}