.firstBlock {
    padding-top: 2rem;
}

.headingBlue {
    background-color: #04c7ff;
    background-image: url("https://s3.eu-central-1.amazonaws.com/cdn.eu.com/c/islandcodes/img/SpikesTitleBlue.png");
    background-repeat: no-repeat;
    background-size: cover;
}

.searchBlock {
    background-color: #ff6d32;
    background-image: url("https://s3.eu-central-1.amazonaws.com/cdn.eu.com/c/islandcodes/img/spikesOrange.png");
    background-repeat: round;
    background-size: cover;
}

.searchBlockRow {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
}

.innerSearchBlock {
    background-image: linear-gradient(#f7bf80, #f7865a);
    padding-left: 1rem;
    padding-right: 1rem;
    width: 95%;
}

.innerSearchBlock .md-form {
    background-color: white;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}

.innerSearchBlock .md-form input[type=text]:focus:not([readonly]) + label {
    color: black;
}

.innerSearchBlock .form-check-input[type="checkbox"]:checked + label:before, 
.innerSearchBlock label.btn input[type="checkbox"]:checked + label:before {
    border-right: 2px solid #000000;
    border-bottom: 2px solid #000000;
}


.allMapsArea {
    background-color: #04c7ff;
    background-image: url("https://s3.eu-central-1.amazonaws.com/cdn.eu.com/c/islandcodes/img/spikesBlueTop.png"), url("https://cdn.eu.com/c/islandcodes/img/spikesBlueBottom.png");
    background-position: top left, bottom;
   background-repeat: no-repeat, no-repeat;
   background-size: contain, contain;
    padding-left: 0rem;
    padding-right: 0rem;
}

.allMapsArea .card {
    height: 100%;
}

.creatorMapsArea {
    padding-left: 1.6rem;
    padding-right: 1.6rem;
}

.fortnite-btn-code:after {
    height: 30px!important
}

.goldenBG {
    background-color: #dfa130;
    background-repeat: no-repeat;
}

.searchMobile {
    display: block;
}

.searchLarge {
    display: none;
}

.gamemodesMobile {
    display: block;
}

.gamemodesLarge {
    display: none;
}



@media (min-width: 576px) and (max-width: 767px) {
    .innerSearchBlock {
        width: 85%;
        padding-left: 1.6rem;
        padding-right: 1.6rem;
        -webkit-clip-path: polygon(1% 0, 99% 0, 100% 100%, 0% 100%);
    clip-path: polygon(1% 0, 99% 0, 100% 100%, 0% 100%);
    }

    .searchBlockRow {
        padding-left: 0.7rem;
        padding-right: 0.7rem;
    }
    .firstBlock {
        padding-top: 3rem;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .innerSearchBlock {
        width: 85%;
        padding-left: 2rem;
        padding-right: 2rem;
        -webkit-clip-path: polygon(2% 0, 98% 0, 100% 100%, 0% 100%);
    clip-path: polygon(2% 0, 98% 0, 100% 100%, 0% 100%);
    }
    .searchBlockRow {
        padding-left: 1rem;
        padding-right: 1rem;
    }
    .firstBlock {
        padding-top: 3rem;
    }
    .gamemodesMobile {
        display: none;
    }
    
    .gamemodesLarge {
        display: block;
    }

}

@media (min-width: 992px) and (max-width: 1199px) {
    .innerSearchBlock {
        width: 85%;
        
    }
    .gamemodesMobile {
        display: none;
    }
    
    .gamemodesLarge {
        display: block;
    }
}


@media (min-width: 1200px) {
    .innerSearchBlock {
        padding-left: 3rem;
        padding-right: 3rem;
        width: 75%;
        -webkit-clip-path: polygon(2% 0, 98% 0, 100% 100%, 0% 100%);
    clip-path: polygon(2% 0, 98% 0, 100% 100%, 0% 100%);
    }
    .allMapsArea, .creatorMapsArea {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
    }
    .firstBlock {
        padding-top: 4rem;
    }
    .searchMobile {
        display: none;
    }
    
    .searchLarge {
        display: block;
    }
    .gamemodesMobile {
        display: none;
    }
    
    .gamemodesLarge {
        display: block;
    }
}

@media (min-width: 1800px) {
    .innerSearchBlock {
        padding-left: 3rem;
        padding-right: 3rem;
        width: 75%;
        -webkit-clip-path: polygon(2% 0, 98% 0, 100% 100%, 0% 100%);
    clip-path: polygon(2% 0, 98% 0, 100% 100%, 0% 100%);
    }
    .allMapsArea, .creatorMapsArea {
        padding-left: 4rem;
        padding-right: 4rem;
    }
    .firstBlock {
        padding-top: 5rem;
    }
    .searchMobile {
        display: none;
    }
    
    .searchLarge {
        display: block;
    }
    .gamemodesMobile {
        display: none;
    }
    
    .gamemodesLarge {
        display: block;
    }
}