
.nav-link:hover {
    color: #FFEB3B !important;
}

.logo {
    display: none
}

#mainContainer {
    margin-top: 50px;
}

#logoText {
    display: none;
}

.navbar-brand {
    padding: 0px;
}

@media (min-width: 768px) and (max-width: 1149px) {
    
    .main-logo {
        width: 20rem;
    }
    .logo {
        display: block
    }
    #logoMobile, .navbar-brand {
        display: none;
    }
    
    .navbar-brand {
        padding-top: .3125rem;
        padding-bottom: .3125rem;
    }
}

@media (min-width: 1150px) {
    .logo {
        display: block
    }
    .main-logo {
        width: 20rem;
    }
    #logoMobile {
        display: none;
    }
    #logoText {
        display: block;
    }
    .navbar-brand {
        padding-top: .3125rem;
        padding-bottom: .3125rem;
    }
}


.w-33 {
  width: 33% !important;
}

.card {
  border-radius: 0px!important;
}
p {
  font-size: 1.4rem;
}

.bg-blue {
  background-color: #0dc1fc;
}

#mainContainer > .row {
  margin-right: 0px;
  margin-left: 0px;
}

.poligonButton1 {
  clip-path: polygon(0% 3%,97% 0%,100% 100%,0% 90%);
  -webkit-clip-path: polygon(0% 3%,97% 0%,100% 100%,0% 90%);
}

footer {
  font-size: 1.3rem;
}

.outerButtonAllMaps {
  position: absolute;
    bottom: 0;
    width: 100%;
}

.polyCard1 {
  -webkit-clip-path: polygon(2% 100%,0 0,5% 1%,8% 5%,8% 1%,98% 4%,100% 100%);
  clip-path: polygon(2% 100%,0 0,5% 1%,8% 5%,8% 1%,98% 4%,100% 100%);
  height: 12rem;
  background-color: #f0ed63;
}

.polyCard2 {
  -webkit-clip-path: polygon(0 100%,2% 5%,90% 0,90% 5%,93% 0,100% 0,96% 100%);
  clip-path: polygon(0 100%,2% 5%,90% 0,90% 5%,93% 0,100% 0,96% 100%);
  height: 12rem;
  background-color: #f0ed63;
}

.polyCardText {
    position: relative;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}

html,
body,
main,
#root,
#root > div:first-child,
.fullHeight {
  /* height: 100%; */
  /* overflow-x: hidden; */
}

#mainContainer, #mainContainer > div {
  /* overflow-x: hidden; */
}

.homeFront {
  background-color: #04c7ff;
  background-image: url("https://s3.eu-central-1.amazonaws.com/cdn.eu.com/c/islandcodes/img/spikesBlueTop.png"), url("https://cdn.eu.com/c/islandcodes/img/spikesBlueBottom.png");
  background-position: top left, bottom;
 background-repeat: no-repeat, no-repeat;
 background-size: contain, contain;
}

.homeFront:after {
  content: "";
  display: block;
  background: #e25656;
  position: absolute;
  left: 0;
  right: 0;
  bottom: -1em;
  height: 6em;
  -webkit-clip-path: polygon(0 70%, 100% 0, 100% 100%, 0 100%);
  clip-path: polygon(0 70%, 100% 0, 100% 100%, 0 100%);
}

.featured {
  background-image: url("https://cdn.eu.com/c/islandcodes/img/spikesRed.png"), linear-gradient(#e25656, #841919);
  background-repeat: round;
}

.featuredHeading {
  text-shadow: 3px 3px #1b1b1b;
}

.featuredCard1 {
  background-image: url(https://cdn.eu.com/c/islandcodes/img/featuredCard1.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-color: transparent;
  box-shadow: none;
}

.featuredCard2 {
  background-image: url(https://cdn.eu.com/c/islandcodes/img/featuredCard2.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-color: transparent;
  box-shadow: none;
}

.featuredCard3 {
  background-image: url(https://cdn.eu.com/c/islandcodes/img/featuredCard3.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-color: transparent;
  box-shadow: none;
}

.addIsland {
  background-color: #0dc1fc;
  background-image: url("https://s3.eu-central-1.amazonaws.com/cdn.eu.com/c/islandcodes/img/spikesBlueTop.png"), url("https://cdn.eu.com/c/islandcodes/img/spikesBlueBottom.png");
  background-position: top left, bottom;
 background-repeat: no-repeat, no-repeat;
 background-size: contain, contain;
}



.featured:after {
  content: "";
  display: block;
  background: #0dc1fc;
  left: 0;
  right: 0;
  bottom: -1em;
  margin-bottom: -1px;
  height: 6em;
  -webkit-clip-path: polygon(0 70%, 100% 0, 100% 100%, 0 100%);
  clip-path: polygon(0 70%, 100% 0, 100% 100%, 0 100%);
}

.mainImage {
  max-width: 80%;
  margin-top: 8rem;
}

.figure1 {
  width: 33%;
  margin-top: -12rem;
}

.figure2 {
  width: 40%;
  margin-top: -19rem;
}

.allMapsBtn {
  bottom: -10rem;
  width: 17rem;
  animation: bounce2 5s infinite;
  -webkit-animation: bounce 4s infinite;
}

.addIslandImages {
  width: 80%;
}

.addIsland:after {
  content: "";
  display: block;
  background: #ffb864;
  left: 0;
  right: 0;
  bottom: -1em;
  margin-bottom: -1px;
  height: 6em;
  -webkit-clip-path: polygon(0 0, 100% 60%, 100% 100%, 0% 100%);
clip-path: polygon(0 0, 100% 60%, 100% 100%, 0% 100%);
}

.handy {
  width: 30rem;
  margin-top: -7rem;
  max-width: 90%;
}

.ButtonsSection {
  background-image: linear-gradient(#ffb864, #ff6d32);
  background-repeat: no-repeat;
}



.ButtonsSection:after {
  content: "";
  display: block;
  background: #000;
  left: 0;
  right: 0;
  bottom: -1em;
  margin-bottom: -1px;
  height: 6em;
  -webkit-clip-path: polygon(0 70%, 100% 0, 100% 100%, 0 100%);
  clip-path: polygon(0 70%, 100% 0, 100% 100%, 0 100%);
}

.FAQ {
  background-color: #000;
}

.fortnite-btn {
  position: relative;
  font-size: 2em;
  color: #fff;
  margin: 0 auto;
  background: transparent;
  color: #000;
  font-weight: 900;
  text-transform: uppercase;
  width: 250px;
  padding: 18px 0;
  text-align: center;
  line-height: 1;
  cursor: pointer;
  -webkit-clip-path: polygon(0 0,100% 0,98% 100%,2% 100%);
  clip-path: polygon(0 0,100% 0,98% 100%,2% 100%);
}

.fortnite-btn-sm {
  font-size: 1.2em!important;
  width: 100%;
  padding-left: 1.4rem;
  padding-right: 1.4rem;

}

.fortnite-btn-sm:after {
  height: 45px!important;
}

.fortnite-btn:hover:after {
  background-color: #eeea4c;
}

.fortnite-btn span, .fortnite-btn button {
  position: relative;
  z-index: 100;
  background-color: #f0ed63;
}

.fortnite-btn:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 65px;
  transition: background-color .3s ease-in-out;
  box-shadow: 0 6px 12px -4px rgba(0, 0, 0, 0.705);
  -webkit-transform-origin: 50% 50% 20px;
          transform-origin: 50% 50% 20px;
  background-color: #f0ed63;
}

.polycard-1 {
  
  -webkit-clip-path: polygon(0 0, 100% 0, 99% 100%, 1% 100%);
clip-path: polygon(0 0, 100% 0, 99% 100%, 1% 100%);
}

@-webkit-keyframes bounce {
  0% {
-webkit-transform: scale(1,1) translate(0px, 0px);
        transform: scale(1,1) translate(0px, 0px);
}

30%{
-webkit-transform: scale(1,0.8) translate(0px, 8px);
        transform: scale(1,0.8) translate(0px, 8px); 
}

75%{
-webkit-transform: scale(1,1.1) translate(0px, -15px);
        transform: scale(1,1.1) translate(0px, -15px); 
}

100% {
-webkit-transform: scale(1,1) translate(0px, 0px);
        transform: scale(1,1) translate(0px, 0px);
}
}

@-webkit-keyframes bounce2 {
  0% {
    -webkit-transform: scale(1,1) translate(0px, 0px);
            transform: scale(1,1) translate(0px, 0px);
    }
    
    30%{
    -webkit-transform: scale(1,0.8) translate(0px, 8px);
            transform: scale(1,0.8) translate(0px, 8px); 
    }
    
    75%{
    -webkit-transform: scale(1,1.1) translate(0px, -15px);
            transform: scale(1,1.1) translate(0px, -15px); 
    }
    
    100% {
    -webkit-transform: scale(1,1) translate(0px, 0px);
            transform: scale(1,1) translate(0px, 0px);
    }
}

@keyframes bounce2 {
  0% {
    -webkit-transform: scale(1,1) translate(0px, 0px);
            transform: scale(1,1) translate(0px, 0px);
    }
    
    30%{
    -webkit-transform: scale(1,0.8) translate(0px, 8px);
            transform: scale(1,0.8) translate(0px, 8px); 
    }
    
    75%{
    -webkit-transform: scale(1,1.1) translate(0px, -15px);
            transform: scale(1,1.1) translate(0px, -15px); 
    }
    
    100% {
    -webkit-transform: scale(1,1) translate(0px, 0px);
            transform: scale(1,1) translate(0px, 0px);
    }
}

.figureBlock {
  position: absolute;
  right: 2rem;
  bottom: -6rem;
  width: 33%;
}


@media (max-width: 576px) {

  html,
  body,
  main,
  #root,
  #root > div:first-child,
  .fullHeight {
    /* height: 100%; */
  }

  .mainImage {
    max-width: 90%;
    margin-top: 4rem;
  }

  .figure1 {
    width: 40%;
    margin-top: 0rem;
  }

  .figure2 {
    width: 50%;
    margin-top: -3.5rem;
  }
  .figureBlock {
    width: 45%;
    right: 0px;
  }



  .allMapsBtn {
    bottom: 3.5rem;
    width: 6rem;
  }
  .homeFront:after {
    -webkit-clip-path: polygon(0 6%, 100% 0, 100% 100%, 0% 100%);
    clip-path: polygon(0 50%, 100% 0, 100% 100%, 0% 100%);
}
.polyCard1, .polyCard2 {
  height: 5rem;
  width: 75%;
margin-left: auto;
margin-right: auto;
}

}

@media (min-width: 401px) and (max-width: 740px) {


}

@media (min-width: 576px) {

  .featuredHeading {
      font-size: 6rem;
      font-weight: 300;
      line-height: 1.2;
  }
}

@media (min-width: 741px) and (max-width: 850px) {

  .figure1 {
    width: 33%;
    margin-top: -3rem;
  }

  .figure2 {
    width: 40%;
    margin-top: -9rem;
  }
 
  .allMapsBtn {
    bottom: 3rem;
    width: 10rem
  }
}

@media (min-width: 741px) and (max-width: 1199px) {

  .allMapsBtn {
    bottom: 3rem;
    width: 12rem
  }
}



@media (min-width: 1200px) and (max-width: 1599px) {
  .mainImage {
    max-width: 900px;
  }
  .allMapsBtn {
    bottom: 3.5rem;
    width: 14rem
  }
  
}

@media (min-width: 1600px) {

  .mainImage {
    max-width: 900px;
  }
  .allMapsBtn {
    bottom: 3.5rem;
    width: 17rem
  }
  
}
.gameModeBadge {
    border-radius: 0px;
    clip-path: polygon(0% 3%,97% 0%,100% 100%,0% 90%);
    -webkit-clip-path: polygon(0% 3%,97% 0%,100% 100%,0% 90%);
    font-size: 1.6rem;
    margin-bottom: 2rem;
}

.codeBlock {
    clip-path: polygon(0% 3%,97% 0%,100% 100%,0% 90%);
    -webkit-clip-path: polygon(0% 3%,97% 0%,100% 100%,0% 90%);
}

.mapBlockImage .Ripple-parent {
    touch-action: unset;
}

.mapBlockImage .rgba-white-slight {
    background-color: unset;
}

.creatorBlockButtonOuter {
    height: 92px;
}

.creatorBlockButton {
    position: absolute;
    width: 100%;
    bottom: 1rem;
    padding-right: 2.2rem;
}

.mapBlockName {
    font-size: 1.7rem;
    text-transform: uppercase!important;
}
.mapBlockCreator {
    font-size: 1.4rem;
}

.creatorBlockDesc {
    font-size: 1rem;
}
.creatorBlockButtonShow {
    font-size: 1.1rem;
}

@media (min-width: 576px) and (max-width: 767px) {
    .mapBlockName {
    font-size: 1.8rem;
    }
    .mapBlockCreator {
        font-size: 1.5rem;
    }
    .creatorBlockDesc {
        font-size: 1.4rem;
    }
    .creatorBlockButtonShow {
        font-size: 1.5rem;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .mapBlockName {
    font-size: 1.9rem;
    }
    .mapBlockCreator {
        font-size: 1.6rem;
    }
    .creatorBlockDesc {
        font-size: 1.4rem;
    }
    .creatorBlockButtonShow {
        font-size: 1.5rem;
    }
}

@media (min-width: 992px) and (max-width: 1199px) {
    .mapBlockName {
    font-size: 1.9rem;
    }
    .mapBlockCreator {
        font-size: 1.7rem;
    }
    .creatorBlockDesc {
        font-size: 1.4rem;
    }
    .creatorBlockButtonShow {
        font-size: 1.8rem;
    }
}

@media (min-width: 1200px) {
    .mapBlockName {
    font-size: 2.0rem;
    }
    .mapBlockCreator {
        font-size: 1.7rem;
    }
    .creatorBlockDesc {
        font-size: 1.4rem;
    }
    .creatorBlockButtonShow {
        font-size: 1.8rem;
    }
}

@media (min-width: 1800px) {
    .mapBlockName {
    font-size: 2.3rem;
    }
    .mapBlockCreator {
        font-size: 1.8rem;
    }
    .creatorBlockDesc {
        font-size: 1.4rem;
    }
    .creatorBlockButtonShow {
        font-size: 1.8rem;
    }
}
.singleMapDesc td {
    font-size: 2.2rem;
}

.singleMapDesc .fa-discord {color: #738adb} .singleMapDesc .fa-youtube {color: #cd201f} .singleMapDesc .fa-twitter {color: #fff} .singleMapDesc .fa-instagram {color: #e4405f} .singleMapDesc .fa-twitch {color: #8641dc}
.singleMapDesc .gamemode {
    width: 25%;
}

.nameBlock {
    background-image: url("https://s3.eu-central-1.amazonaws.com/cdn.eu.com/c/islandcodes/img/spikesOrange.png"), linear-gradient(#ff994f, #ff6d32);
    background-repeat: no-repeat;
}


.mediaArea {
    background-image: url("https://s3.eu-central-1.amazonaws.com/cdn.eu.com/c/islandcodes/img/spikesMap.png"), linear-gradient(#ff6e33, #ffb764);
    background-repeat: round;
}

.mapArea {
    background-image: url("https://s3.eu-central-1.amazonaws.com/cdn.eu.com/c/islandcodes/img/spikesBlueTop.png"), url("https://cdn.eu.com/c/islandcodes/img/spikesBlueBottom.png"), linear-gradient(#02b6fe, #097ef5);
    background-position: top left, bottom;
   background-repeat: no-repeat, no-repeat;
   background-size: contain, contain;
   padding-right: 0px;
    padding-left: 0px;
}

.mapArea:after {
    content: "";
    display: block;
    background: #ff6e33;
    left: 0;
    right: 0;
    bottom: -1em;
    margin-bottom: -1px;
    height: 6em;
    -webkit-clip-path: polygon(0 70%, 0% 100%, 100% 100%);
clip-path: polygon(0 70%, 0% 100%, 100% 100%);
  }

.creatorMapsArea {
    background-image: linear-gradient(#115af1, #02b6fe);
    background-repeat: no-repeat;
}

.mapAltImg {
    width: 80%;
    max-width: 800px;
}

.mapVideo {
    width: 300px;
    height: 169px;
}


@media (min-width: 401px) and (max-width: 740px) {
    .mapVideo {
        width: 380px;
        height: 214px;
    }
}

@media (min-width: 741px) and (max-width: 850px) {
    .mapVideo {
        width: 700px;
        height: 394px;
    }
}

@media (min-width: 576px) {
    .mapVideo {
        width: 800px;
        height: 450px;
    }
    .display1-md {
        font-size: 6rem;
        font-weight: 300;
        line-height: 1.2;
    }
}

@media (min-width: 851px) {
    .mapVideo {
        width: 800px;
        height: 450px;
    }
}



@-webkit-keyframes closeWindow {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  
  @keyframes closeWindow {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  
  .ril__outer {
    background-color: rgba(0, 0, 0, 0.85);
    outline: none;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;
    width: 100%;
    height: 100%;
    -ms-content-zooming: none;
    -ms-user-select: none;
    -ms-touch-select: none;
    touch-action: none;
  }
  
  .ril__outerClosing {
    opacity: 0;
  }
  
  .ril__inner {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  
  .ril__image,
  .ril__imagePrev,
  .ril__imageNext {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    max-width: none;
    -ms-content-zooming: none;
    -ms-user-select: none;
    -ms-touch-select: none;
    touch-action: none;
  }
  
  .ril__imageDiscourager {
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }
  
  .ril__navButtons {
    border: none;
    position: absolute;
    top: 0;
    bottom: 0;
    width: 20px;
    height: 34px;
    padding: 40px 30px;
    margin: auto;
    cursor: pointer;
    opacity: 0.7;
  }
  .ril__navButtons:hover {
    opacity: 1;
  }
  .ril__navButtons:active {
    opacity: 0.7;
  }
  
  .ril__navButtonPrev {
    left: 0;
    background: rgba(0, 0, 0, 0.2)
      url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZlcnNpb249IjEuMSIgd2lkdGg9IjIwIiBoZWlnaHQ9IjM0Ij48cGF0aCBkPSJtIDE5LDMgLTIsLTIgLTE2LDE2IDE2LDE2IDEsLTEgLTE1LC0xNSAxNSwtMTUgeiIgZmlsbD0iI0ZGRiIvPjwvc3ZnPg==')
      no-repeat center;
  }
  
  .ril__navButtonNext {
    right: 0;
    background: rgba(0, 0, 0, 0.2)
      url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZlcnNpb249IjEuMSIgd2lkdGg9IjIwIiBoZWlnaHQ9IjM0Ij48cGF0aCBkPSJtIDEsMyAyLC0yIDE2LDE2IC0xNiwxNiAtMSwtMSAxNSwtMTUgLTE1LC0xNSB6IiBmaWxsPSIjRkZGIi8+PC9zdmc+')
      no-repeat center;
  }
  
  .ril__downloadBlocker {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url('data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7');
    background-size: cover;
  }
  
  .ril__caption,
  .ril__toolbar {
    background-color: rgba(0, 0, 0, 0.5);
    position: absolute;
    left: 0;
    right: 0;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
  }
  
  .ril__caption {
    bottom: 0;
    max-height: 150px;
    overflow: auto;
  }
  
  .ril__captionContent {
    padding: 10px 20px;
    color: #fff;
  }
  
  .ril__toolbar {
    top: 0;
    height: 50px;
  }
  
  .ril__toolbarSide {
    height: 50px;
    margin: 0;
  }
  
  .ril__toolbarLeftSide {
    padding-left: 20px;
    padding-right: 0;
    -webkit-flex: 0 1 auto;
            flex: 0 1 auto;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  .ril__toolbarRightSide {
    padding-left: 0;
    padding-right: 20px;
    -webkit-flex: 0 0 auto;
            flex: 0 0 auto;
  }
  
  .ril__toolbarItem {
    display: inline-block;
    line-height: 50px;
    padding: 0;
    color: #fff;
    font-size: 120%;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  
  .ril__toolbarItemChild {
    vertical-align: middle;
  }
  
  .ril__builtinButton {
    width: 40px;
    height: 35px;
    cursor: pointer;
    border: none;
    opacity: 0.7;
  }
  .ril__builtinButton:hover {
    opacity: 1;
  }
  .ril__builtinButton:active {
    outline: none;
  }
  
  .ril__builtinButtonDisabled {
    cursor: default;
    opacity: 0.5;
  }
  .ril__builtinButtonDisabled:hover {
    opacity: 0.5;
  }
  
  .ril__closeButton {
    background: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZlcnNpb249IjEuMSIgd2lkdGg9IjIwIiBoZWlnaHQ9IjIwIj48cGF0aCBkPSJtIDEsMyAxLjI1LC0xLjI1IDcuNSw3LjUgNy41LC03LjUgMS4yNSwxLjI1IC03LjUsNy41IDcuNSw3LjUgLTEuMjUsMS4yNSAtNy41LC03LjUgLTcuNSw3LjUgLTEuMjUsLTEuMjUgNy41LC03LjUgLTcuNSwtNy41IHoiIGZpbGw9IiNGRkYiLz48L3N2Zz4=')
      no-repeat center;
  }
  
  .ril__zoomInButton {
    background: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMCIgaGVpZ2h0PSIyMCI+PGcgc3Ryb2tlPSIjZmZmIiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCI+PHBhdGggZD0iTTEgMTlsNi02Ii8+PHBhdGggZD0iTTkgOGg2Ii8+PHBhdGggZD0iTTEyIDV2NiIvPjwvZz48Y2lyY2xlIGN4PSIxMiIgY3k9IjgiIHI9IjciIGZpbGw9Im5vbmUiIHN0cm9rZT0iI2ZmZiIgc3Ryb2tlLXdpZHRoPSIyIi8+PC9zdmc+')
      no-repeat center;
  }
  
  .ril__zoomOutButton {
    background: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMCIgaGVpZ2h0PSIyMCI+PGcgc3Ryb2tlPSIjZmZmIiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCI+PHBhdGggZD0iTTEgMTlsNi02Ii8+PHBhdGggZD0iTTkgOGg2Ii8+PC9nPjxjaXJjbGUgY3g9IjEyIiBjeT0iOCIgcj0iNyIgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjZmZmIiBzdHJva2Utd2lkdGg9IjIiLz48L3N2Zz4=')
      no-repeat center;
  }
  
  .ril__outerAnimating {
    -webkit-animation-name: closeWindow;
            animation-name: closeWindow;
  }
  
  @-webkit-keyframes pointFade {
    0%,
    19.999%,
    100% {
      opacity: 0;
    }
    20% {
      opacity: 1;
    }
  }
  
  @keyframes pointFade {
    0%,
    19.999%,
    100% {
      opacity: 0;
    }
    20% {
      opacity: 1;
    }
  }
  
  .ril__loadingCircle {
    width: 60px;
    height: 60px;
    position: relative;
  }
  
  .ril__loadingCirclePoint {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
  }
  .ril__loadingCirclePoint::before {
    content: '';
    display: block;
    margin: 0 auto;
    width: 11%;
    height: 30%;
    background-color: #fff;
    border-radius: 30%;
    -webkit-animation: pointFade 800ms infinite ease-in-out both;
            animation: pointFade 800ms infinite ease-in-out both;
  }
  .ril__loadingCirclePoint:nth-of-type(1) {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  .ril__loadingCirclePoint:nth-of-type(7) {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
  .ril__loadingCirclePoint:nth-of-type(1)::before,
  .ril__loadingCirclePoint:nth-of-type(7)::before {
    -webkit-animation-delay: -800ms;
            animation-delay: -800ms;
  }
  .ril__loadingCirclePoint:nth-of-type(2) {
    -webkit-transform: rotate(30deg);
            transform: rotate(30deg);
  }
  .ril__loadingCirclePoint:nth-of-type(8) {
    -webkit-transform: rotate(210deg);
            transform: rotate(210deg);
  }
  .ril__loadingCirclePoint:nth-of-type(2)::before,
  .ril__loadingCirclePoint:nth-of-type(8)::before {
    -webkit-animation-delay: -666ms;
            animation-delay: -666ms;
  }
  .ril__loadingCirclePoint:nth-of-type(3) {
    -webkit-transform: rotate(60deg);
            transform: rotate(60deg);
  }
  .ril__loadingCirclePoint:nth-of-type(9) {
    -webkit-transform: rotate(240deg);
            transform: rotate(240deg);
  }
  .ril__loadingCirclePoint:nth-of-type(3)::before,
  .ril__loadingCirclePoint:nth-of-type(9)::before {
    -webkit-animation-delay: -533ms;
            animation-delay: -533ms;
  }
  .ril__loadingCirclePoint:nth-of-type(4) {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
  }
  .ril__loadingCirclePoint:nth-of-type(10) {
    -webkit-transform: rotate(270deg);
            transform: rotate(270deg);
  }
  .ril__loadingCirclePoint:nth-of-type(4)::before,
  .ril__loadingCirclePoint:nth-of-type(10)::before {
    -webkit-animation-delay: -400ms;
            animation-delay: -400ms;
  }
  .ril__loadingCirclePoint:nth-of-type(5) {
    -webkit-transform: rotate(120deg);
            transform: rotate(120deg);
  }
  .ril__loadingCirclePoint:nth-of-type(11) {
    -webkit-transform: rotate(300deg);
            transform: rotate(300deg);
  }
  .ril__loadingCirclePoint:nth-of-type(5)::before,
  .ril__loadingCirclePoint:nth-of-type(11)::before {
    -webkit-animation-delay: -266ms;
            animation-delay: -266ms;
  }
  .ril__loadingCirclePoint:nth-of-type(6) {
    -webkit-transform: rotate(150deg);
            transform: rotate(150deg);
  }
  .ril__loadingCirclePoint:nth-of-type(12) {
    -webkit-transform: rotate(330deg);
            transform: rotate(330deg);
  }
  .ril__loadingCirclePoint:nth-of-type(6)::before,
  .ril__loadingCirclePoint:nth-of-type(12)::before {
    -webkit-animation-delay: -133ms;
            animation-delay: -133ms;
  }
  .ril__loadingCirclePoint:nth-of-type(7) {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
  .ril__loadingCirclePoint:nth-of-type(13) {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
  .ril__loadingCirclePoint:nth-of-type(7)::before,
  .ril__loadingCirclePoint:nth-of-type(13)::before {
    -webkit-animation-delay: 0ms;
            animation-delay: 0ms;
  }
  
  .ril__loadingContainer {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  .ril__imagePrev .ril__loadingContainer,
  .ril__imageNext .ril__loadingContainer {
    display: none;
  }
  
  .ril__errorContainer {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    color: #fff;
  }
  .ril__imagePrev .ril__errorContainer,
  .ril__imageNext .ril__errorContainer {
    display: none;
  }
  
  .ril__loadingContainer__icon {
    color: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translateX(-50%) translateY(-50%);
            transform: translateX(-50%) translateY(-50%);
  }
.firstBlock {
    padding-top: 2rem;
}

.headingBlue {
    background-color: #04c7ff;
    background-image: url("https://s3.eu-central-1.amazonaws.com/cdn.eu.com/c/islandcodes/img/SpikesTitleBlue.png");
    background-repeat: no-repeat;
    background-size: cover;
}

.searchBlock {
    background-color: #ff6d32;
    background-image: url("https://s3.eu-central-1.amazonaws.com/cdn.eu.com/c/islandcodes/img/spikesOrange.png");
    background-repeat: round;
    background-size: cover;
}

.searchBlockRow {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
}

.innerSearchBlock {
    background-image: linear-gradient(#f7bf80, #f7865a);
    padding-left: 1rem;
    padding-right: 1rem;
    width: 95%;
}

.innerSearchBlock .md-form {
    background-color: white;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}

.innerSearchBlock .md-form input[type=text]:focus:not([readonly]) + label {
    color: black;
}

.innerSearchBlock .form-check-input[type="checkbox"]:checked + label:before, 
.innerSearchBlock label.btn input[type="checkbox"]:checked + label:before {
    border-right: 2px solid #000000;
    border-bottom: 2px solid #000000;
}


.allMapsArea {
    background-color: #04c7ff;
    background-image: url("https://s3.eu-central-1.amazonaws.com/cdn.eu.com/c/islandcodes/img/spikesBlueTop.png"), url("https://cdn.eu.com/c/islandcodes/img/spikesBlueBottom.png");
    background-position: top left, bottom;
   background-repeat: no-repeat, no-repeat;
   background-size: contain, contain;
    padding-left: 0rem;
    padding-right: 0rem;
}

.allMapsArea .card {
    height: 100%;
}

.creatorMapsArea {
    padding-left: 1.6rem;
    padding-right: 1.6rem;
}

.fortnite-btn-code:after {
    height: 30px!important
}

.goldenBG {
    background-color: #dfa130;
    background-repeat: no-repeat;
}

.searchMobile {
    display: block;
}

.searchLarge {
    display: none;
}

.gamemodesMobile {
    display: block;
}

.gamemodesLarge {
    display: none;
}



@media (min-width: 576px) and (max-width: 767px) {
    .innerSearchBlock {
        width: 85%;
        padding-left: 1.6rem;
        padding-right: 1.6rem;
        -webkit-clip-path: polygon(1% 0, 99% 0, 100% 100%, 0% 100%);
    clip-path: polygon(1% 0, 99% 0, 100% 100%, 0% 100%);
    }

    .searchBlockRow {
        padding-left: 0.7rem;
        padding-right: 0.7rem;
    }
    .firstBlock {
        padding-top: 3rem;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .innerSearchBlock {
        width: 85%;
        padding-left: 2rem;
        padding-right: 2rem;
        -webkit-clip-path: polygon(2% 0, 98% 0, 100% 100%, 0% 100%);
    clip-path: polygon(2% 0, 98% 0, 100% 100%, 0% 100%);
    }
    .searchBlockRow {
        padding-left: 1rem;
        padding-right: 1rem;
    }
    .firstBlock {
        padding-top: 3rem;
    }
    .gamemodesMobile {
        display: none;
    }
    
    .gamemodesLarge {
        display: block;
    }

}

@media (min-width: 992px) and (max-width: 1199px) {
    .innerSearchBlock {
        width: 85%;
        
    }
    .gamemodesMobile {
        display: none;
    }
    
    .gamemodesLarge {
        display: block;
    }
}


@media (min-width: 1200px) {
    .innerSearchBlock {
        padding-left: 3rem;
        padding-right: 3rem;
        width: 75%;
        -webkit-clip-path: polygon(2% 0, 98% 0, 100% 100%, 0% 100%);
    clip-path: polygon(2% 0, 98% 0, 100% 100%, 0% 100%);
    }
    .allMapsArea, .creatorMapsArea {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
    }
    .firstBlock {
        padding-top: 4rem;
    }
    .searchMobile {
        display: none;
    }
    
    .searchLarge {
        display: block;
    }
    .gamemodesMobile {
        display: none;
    }
    
    .gamemodesLarge {
        display: block;
    }
}

@media (min-width: 1800px) {
    .innerSearchBlock {
        padding-left: 3rem;
        padding-right: 3rem;
        width: 75%;
        -webkit-clip-path: polygon(2% 0, 98% 0, 100% 100%, 0% 100%);
    clip-path: polygon(2% 0, 98% 0, 100% 100%, 0% 100%);
    }
    .allMapsArea, .creatorMapsArea {
        padding-left: 4rem;
        padding-right: 4rem;
    }
    .firstBlock {
        padding-top: 5rem;
    }
    .searchMobile {
        display: none;
    }
    
    .searchLarge {
        display: block;
    }
    .gamemodesMobile {
        display: none;
    }
    
    .gamemodesLarge {
        display: block;
    }
}



.FeaturedBlock {
    background-image: url(https://cdn.eu.com/c/islandcodes/img/spikesRed.png), linear-gradient(#e25656, #841919);;
    background-repeat: round;
}

.carousel-multi-item {
    margin-bottom: 3rem;
}


.allMapsAreaFetured {
    background-color: #dddddd;
}

.fortnite-btn-code:after {
    height: 30px!important
}

.BlockWinnerOuter {
    background-image: url("https://s3.eu-central-1.amazonaws.com/cdn.eu.com/c/islandcodes/img/spikesOrange.png"), linear-gradient(#ff8643, #ff6d32);
    background-repeat: round;
    background-size: cover;
}

.BlockWinnerOuter:after {
    content: "";
    display: block;
    background: #dfa130;
    left: 0;
    right: 0;
    bottom: -1em;
    margin-bottom: -1px;
    height: 6em;
    -webkit-clip-path: polygon(0 0, 100% 60%, 100% 100%, 0% 100%);
  clip-path: polygon(0 0, 100% 60%, 100% 100%, 0% 100%);
  }


  .text-shadow {
    text-shadow: 3px 3px #1b1b1b;
  }

  .carousel-multi-item .controls-top .btn-floating {
    background: #ff8181;
}

.carousel-multi-item .carousel-indicators li {

    background-color: #ff8181;

}

.carousel-multi-item .carousel-indicators .active {
    background-color: #ffb4b4;

}
