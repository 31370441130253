.nav-link:hover {
    color: #FFEB3B !important;
}

.logo {
    display: none
}

#mainContainer {
    margin-top: 50px;
}

#logoText {
    display: none;
}

.navbar-brand {
    padding: 0px;
}

@media (min-width: 768px) and (max-width: 1149px) {
    
    .main-logo {
        width: 20rem;
    }
    .logo {
        display: block
    }
    #logoMobile, .navbar-brand {
        display: none;
    }
    
    .navbar-brand {
        padding-top: .3125rem;
        padding-bottom: .3125rem;
    }
}

@media (min-width: 1150px) {
    .logo {
        display: block
    }
    .main-logo {
        width: 20rem;
    }
    #logoMobile {
        display: none;
    }
    #logoText {
        display: block;
    }
    .navbar-brand {
        padding-top: .3125rem;
        padding-bottom: .3125rem;
    }
}

